























































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        order: 0,
        name: "",
        englishName: "",
        group: "",
        status: Boolean,
        discountsIds: [],
        participantGroup: "",
        roomId: "",
        accommodationFrom: "",
        accommodationTo: "",
        accommodationResignation: false,
      }),
    },
    feeGroups: {
      type: Array,
      required: false,
      default: () => [],
    },
    participantGroups: {
      type: Array,
      required: false,
      default: () => [],
    },
    discounts: {
      type: Array,
      required: false,
      default: () => [],
    },
    hotels: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  setup(props, { root }) {
    const rules: {
      [key: string]: (v: any) => boolean | string;
    } = {
      required: (v) => !!v || `${root.$t("layout.misc.required")}`,
    };
    const state = reactive({
      minDate: new Date().toISOString().split("T")[0],
      fromDate: "",
      toDate: "",
    });

    const rooms = computed(() => {
      if (props.hotels && props.hotels.length) {
        const hotels = props.hotels
          .map((el: any) =>
            el.rooms.map((item: any) => ({
              hotelIsEnabled: el.isEnabled,
              hotelName: el.location.name,
              roomIsEnabled: item.isEnabled,
              id: item.id,
              name: item.name,
            }))
          )
          .flat()
          .filter((el: any) => el.hotelIsEnabled && el.roomIsEnabled);
        return hotels;
      } else [];
    });

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          state.fromDate = event.accommodationModule.from.split("T")[0];
          state.toDate = event.accommodationModule.to.split("T")[0];
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onMounted(fetchEvent);

    return { state, rules, rooms };
  },
});
